import { Icon } from '@iconify/react'

const icons = [
  /*{ href: 'https://instagram.com/apehattan.island', icon: 'bi:instagram' },*/
  { href: 'https://twitter.com/apehattancity', icon: 'akar-icons:twitter-fill' },
  { href: '/', icon: 'simple-icons:gitbook' },
  { href: 'https://t.me/apehattan', icon: 'akar-icons:telegram-fill' },
  { href: 'https://www.linkedin.com/company/apehattan', icon: 'simple-icons:linkedin' },
  { href: 'https://discord.gg/aYmrABbxNu', icon: 'akar-icons:discord-fill' },
  /*{ href: 'https://medium.com/@apehattan', icon: 'akar-icons:medium-fill' },*/
]

export const Footer = () => (
  <footer>
    <div className='container'>
      <div className='row gy-5'>
        <div className='d-flex justify-content-center'>
          {icons.map((item, index) => {
            const { href, icon } = item
            return (
              <a key={index} href={href} target='_blank' rel='noreferrer'>
                <Icon className='socials-icon' icon={icon} />
              </a>
            )
          })}
        </div>
      </div>
      <a href='mailto:hello@apehattan.com' className='d-flex justify-content-center mt-3'>
        hello@apehattan.com
      </a>
      <p className='d-flex justify-content-center mt-3'>
        Copyright © 2023 Apehattan. All rights reserved.
      </p>
    </div>
  </footer>
)

