import React, { useEffect, useRef, useState } from 'react'

const Card = ({
  title,
  list,
}: {
  title: string
  list: {
    tag: string
    contents: string[]
  }[]
}) => {
  return (
    <div className='roadmap-card'>
      <p className='title'>{title}</p>
      <ul>
        {list.map(({ tag, contents }) => (
          <li key={tag}>
            <p className='tag'>{tag}</p>
            <p className='content'>
              {contents.map(content => (
                <span key={content}>
                  {content} <br /> <br />
                </span>
              ))}
            </p>
          </li>
        ))}
      </ul>
    </div>
  )
}

export const Roadmap = () => {
  const [range, setRange] = useState(0)
  const roadmapDiv: any = useRef<Element>(null)

  const scrollCards = (value: number) => {
    setRange(value)
    roadmapDiv.current.scrollTo(value, 0)
  }

  useEffect(() => {
    const current = roadmapDiv.current
    current.addEventListener('scroll', () => {
      setRange(current.scrollLeft)
    })
    return () => {
      current.removeEventListener('scroll', () => {
        setRange(current.scrollLeft)
      })
    }
  }, [roadmapDiv])

  return (
    <div className='roadmap-inner'>
      <div className='d-flex align-items-center justify-content-between'>
        <div>
          <h3>Apehattan</h3>
          <h3 className='green'>RoadMap</h3>
        </div>
        <div>
          <p className='text-end note'>Slide the knob to</p>
          <p className='text-end note'>the left or right</p>
        </div>
      </div>
      <div className='slide-container'>
        <input
          type='range'
          min='1'
          max={
            roadmapDiv.current?.scrollWidth
              ? roadmapDiv.current.scrollWidth - 100
              : undefined
          }
          value={range}
          className='slider'
          id='myRange'
          onChange={({ target: { value } }: any) => {
            scrollCards(value)
          }}
        />
      </div>
      <div className='roadmaps' ref={roadmapDiv}>
        <Card
          title='2023'
          list={[
            {
              tag: 'Phase 1 (6-12 months)',
              contents: [
                'Market Research and Development',
                'Proof of Concept',
                'Community Building',
              ],
            },
          ]}
        />
        <Card
          title='2024'
          list={[
            {
              tag: 'Phase 2 (12-18 months)',
              contents: [
                'Private Sales',
                'Token Launch',
                'Core Platform Development',
                'Apehattan 1.0 Release',
              ],
            },
          ]}
        />
        <Card
          title='2025'
          list={[
            {
              tag: 'Phase 3 (12-24 months)',
              contents: [
                'Social Interactions and Experiences Release',
                'Play To Earn Games Development',
                'Island Havoc Release',
                'City Showdown Release',
              ],
            },
          ]}
        />
        <Card
          title='2026 & Beyond'
          list={[
            {
              tag: '-',
              contents: [
                'Mystic Beasts: World Expedition Release',
                'Rooftop Rumble Release',
                'DAO Governance Enabled',
                'And Much More TBA!',
              ],
            },
          ]}
        />
      </div>
    </div>
  )
}
