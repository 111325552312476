import React from 'react'
import { Icon } from '@iconify/react'
import { Roadmap } from './roadmap'
import ownershipIcon from 'assets/Images/icons/customize.svg'
import creativeIcon from 'assets/Images/icons/creative.svg'
import activitiesIcon from 'assets/Images/icons/activities.svg'
import competitionIcon from 'assets/Images/icons/competition.svg'
import marketplaceIcon from 'assets/Images/icons/marketplace.svg'
import playToEarnIcon from 'assets/Images/icons/play-to-earn.svg'
import lifeStyleIcon from 'assets/Images/icons/lifestyle.svg'
import connectIcon from 'assets/Images/icons/connect.svg'
import daoIcon from 'assets/Images/icons/dao.svg'
import tokenAllocation from 'assets/Images/token_allocation.png'

export const Inclusion = () => {
  const aboutSection = document.querySelector('#about')
  const showAbout = () => {
    if (aboutSection) aboutSection.scrollIntoView()
  }

  return (
    <section className='info' id='included'>
      <div className='container'>
        <span className='next' onClick={showAbout}>
          <Icon icon='akar-icons:chevron-up' />
        </span>
        <div className='info-grid'>
          <div className='i-card green'>
            <div className='icon-box mb-5'>
              <img src={ownershipIcon} alt='OWNERSHIP' />
            </div>
            <h3>OWNERSHIP</h3>
            <p>
              Ability To Renovate NFT Buildings And Their Units To Personal
              Homes, Businesses, Virtual Offices, etc.
            </p>
          </div>
          <div className='i-card blue'>
            <div className='icon-box mb-5'>
              <img src={creativeIcon} alt='Inspo' />
            </div>

            <h3 className='blue'>GET CREATIVE</h3>
            <p> Customize Your Character And Interior Design Options</p>
          </div>
          <div className='i-card purple'>
            <div className='icon-box mb-5'>
              <img className='mr-4' src={activitiesIcon} alt='Inspo' />
            </div>

            <h3>ACTIVITIES</h3>
            <p>Social Interactions And Experiences.</p>
          </div>
          <div className='i-card grey'>
            <div className='icon-box mb-5'>
              <img className='mr-4' src={playToEarnIcon} alt='Inspo' />
            </div>

            <h3>PLAY TO EARN</h3>
            <p>Logic And Skill Based eSports Games Like Battle Royale, Racing, etc.</p>
          </div>
          <div className='i-card yellow'>
            <div className='icon-box mb-5'>
              <img className='mr-4' src={competitionIcon} alt='Inspo' />
            </div>
            <h3>COMPETITION</h3>
            <p>Play-To-Earn Rewards With Leaderboards</p>
          </div>
          <div className='i-card blue'>
            <div className='icon-box mb-5'>
              <img className='mr-4' src={connectIcon} alt='Inspo' />
            </div>
            <h3>CONNECT</h3>
            <p>Dedicated Social Media Platform Inside The Metaverse</p>
          </div>
          <div className='i-card purple'>
            <div className='icon-box mb-5'>
              <img className='mr-4' src={marketplaceIcon} alt='Inspo' />
            </div>
            <h3>MARKETPLACE</h3>
            <p>Bid, Mint And Trade NFTs On Apehattan Marketplace.</p>
          </div>
          <div className='i-card green'>
            <div className='icon-box mb-5'>
              <img className='mr-4' src={lifeStyleIcon} alt='Inspo' />
            </div>
            <h3>LIFESTYLE</h3>
            <p>
              Casinos, Shopping Malls, Golf Courses, Night Clubs, Movie
              Theaters, Event Centers & LIVE Concerts, Car Dealerships, Meta
              Dating & Weddings And Much More!
            </p>
          </div>
          <div className='i-card blue'>
            <div className='icon-box mb-5'>
              <img className='mr-4' src={daoIcon} alt='Inspo' />
            </div>
            <h3>DAO</h3>
            <p>Ability To Vote And Guide Project Developments</p>
          </div>
        </div>
      </div>

      <div className='container-fluid roadmap-wrapper'>
        <Roadmap />
      </div>

      <div className='container'>
        <div className='token-allocation'>
          <div className='container'>
            <h3>Token Allocation</h3>

            <div className='row'>
              <div className='col-lg-5 col-12 pr-5'>
                <p>
                  Apehattan token is allocated into seven sectors namely
                  play-to-earn, funds for the ecosystem, team and development,
                  rewards for Apehattan staking, private sales, and our
                  advisors.
                </p>
                <img className='mb-2' src={tokenAllocation} alt='antier' />
              </div>
              <div className='col-lg-1 col-12 ml-5'></div>
              <div className='col-lg-6 col-12 ml-5'>
                <div className='allocation-percent'>
                  <div className='bar green'></div>
                  <div className='item d-flex justify-content-start'>
                    <p>Private Sale</p>
                    <p>10%</p>
                  </div>
                </div>
                <div className='allocation-percent'>
                  <div className='bar orange'></div>
                  <div className='item d-flex justify-content-start'>
                    <p>Public Sale</p>
                    <p>20%</p>
                  </div>
                </div>
                <div className='allocation-percent'>
                  <div className='bar yellow'></div>
                  <div className='item d-flex justify-content-start'>
                    <p>Liquidity</p>
                    <p>10%</p>
                  </div>
                </div>
                <div className='allocation-percent'>
                  <div className='bar blue'></div>
                  <div className='item d-flex justify-content-start'>
                    <p>Play-To-Earn</p>
                    <p>20%</p>
                  </div>
                </div>
                <div className='allocation-percent'>
                  <div className='bar orange2'></div>
                  <div className='item d-flex justify-content-start'>
                    <p>Staking</p>
                    <p>20%</p>
                  </div>
                </div>
                <div className='allocation-percent'>
                  <div className='bar purple'></div>
                  <div className='item d-flex justify-content-start'>
                    <p>Treasury</p>
                    <p>20%</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
