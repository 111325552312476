import { useRef } from 'react'
import logo_white from 'assets/Images/ape-logo-white.svg'
import logo_dark from 'assets/Images/ape-esports-logo-dark.png'
import { Icon } from '@iconify/react'
import { useLocation } from 'react-router-dom'

export const Navbar = ({ scrollPos }: { scrollPos: number }) => {
  const navLinkRef: any = useRef<Element>(null)
  const location = useLocation()

  const showMenu = () => {
    if (navLinkRef) navLinkRef?.current?.classList.add('open')
  }

  const hideMenu = () => {
    if (navLinkRef) navLinkRef?.current?.classList.remove('open')
  }

  const icons = [
    { href: 'https://twitter.com/apehattancity', icon: 'akar-icons:twitter-fill', className: 'twitter' },
    { href: 'https://t.me/apehattan', icon: 'logos:telegram', className: 'telegram' },
    /*{ href: 'https://instagram.com/apehattan.island', icon: 'skill-icons:instagram', className: 'instagram' },*/
    { href: 'https://www.linkedin.com/company/apehattan', icon: 'logos:linkedin-icon', className: 'linkedin' },
    { href: 'https://discord.gg/aYmrABbxNu', icon: 'skill-icons:discord', className: 'discord' },
    /*{ href: 'https://medium.com/@apehattan', icon: 'akar-icons:medium-fill', className: 'medium' },*/
  ]

  return (
    <nav className={scrollPos > 440 ? 'sticky' : undefined}>
      <div className='d-flex gap-5'>
        <a href='/'>
          <img
            src={scrollPos > 440 ? logo_dark : logo_white}
            alt='ape-logo-white'
            className='logo'
          />
        </a>
        <div className='d-flex gap-4'>
          {icons.map((item, index) => {
            const { href, icon, className } = item
            return (
              <a key={index} className={`socials-icon ${className}`} href={href} target='_blank' rel='noreferrer'>
                <Icon icon={icon} />
              </a>
            )
          })}
        </div>
      </div>
      <div className='nav-link' id='navLinks' ref={navLinkRef}>
        <Icon
          className='iconify close'
          icon='clarity:times-line'
          onClick={hideMenu}
        />
        <ul className='mb-0'>
          <li>
            <a href='/' onClick={hideMenu}>HOME</a>
          </li>
          <li className={`${location.hash === '#about' && 'active'}`}>
            <a href='#about' onClick={hideMenu}>ABOUT</a>
          </li>
          <li className={`${location.hash === '#litepaper' && 'active'}`}>
            <a href='#litepaper' onClick={hideMenu}>LITEPAPER</a>
          </li>
          <li>
            <a href='/' onClick={hideMenu}>FAQs</a>
          </li>
          <li className='underline-none'>
            <button className='btn-green btn btn-md'>Coming Soon</button>
          </li>
        </ul>
      </div>
      <Icon
        className='iconify toggle-menu'
        icon='ant-design:menu-outlined'
        onClick={showMenu}
      />
    </nav>
  )
}
