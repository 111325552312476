import React, { LegacyRef, useRef } from 'react'
import { Link } from 'react-router-dom'
import { Icon } from '@iconify/react'
import ab_img from 'assets/Images/about-img.svg'
import Apehattan_video from 'assets/Images/Apehattan.mp4'

export const About = ({
  slide1Ref,
  slide2Ref,
}: {
  slide1Ref: LegacyRef<HTMLDivElement> | undefined
  slide2Ref: LegacyRef<HTMLDivElement> | undefined
}) => {
  const aboutRef: any = useRef(null)

  const showNext = () => {
    const includedSection = document.querySelector('#included')
    if (includedSection) includedSection.scrollIntoView()
  }

  const showHeader = () => {
    window.location.hash = ''
    window.scrollTo(0, 0)
  }

  return (
    <section className='about' id='about' ref={aboutRef}>
      <div className='container'>
        <span className='next mb-6' onClick={showHeader}>
          <Icon icon='akar-icons:chevron-up' />
        </span>
        <div className='row'>
          <div className='col-lg-6 col-12 slides slide_in left' ref={slide1Ref}>
            <h2>Welcome To Apehattan</h2>
            <div className='d-flex gap-4 my-5'>
              <Link
                className='btn btn-sm btn-outline'
                to='apehattan-litepaper.pdf'
                target='_blank'
              >
                Litepaper
              </Link>
              <button className='btn btn-sm btn-outline'>Read FAQs</button>
            </div>
            <p>
              A Truly Immersive And A Cutting-Edge GameFi Metaverse, Making eSports More Accessible And Inviting Gamers Into A Digitally Created, Three-Dimensional Virtual World. Apehattan Aims To Build A Decentralized Global Gaming Community Offering Beyond Entertainment Experiences While Fostering An Ownership Economy.
              <br />
              <br />
              This Visionary Project Aims To Seamlessly Blend The Iconic Landscapes Of Manhattan Island And The Dazzling Allure Of The Las Vegas Strip Into A Singular, Immersive Metaverse Experience, Offering A Huge Space To Invest In Virtual Real Estate. Apehattan Embodies The Magic Of Two Worlds Coming Together, Where Dreams Meet Skyscrapers And Glamour Meets The City That Never Sleeps.
              <br />
              <br />
              <p>
                Apehattan Will Monetize Through Rewards From NFTs, Virtual Real Estate, Advertisements, Businesses, Play To Earn Activities And More!
              </p>
            </p>
          </div>
          <div
            className='col-lg-6 col-12 slides slide_in right'
            ref={slide2Ref}
          >
            <video
              width='100%'
              height='auto'
              poster={ab_img}
              autoPlay={true}
              muted={true}
              controls
              className='click'
              playsInline
            >
              <source src={Apehattan_video} type='video/mp4' />
              Your browser does not support HTML5 video.
            </video>
          </div>
        </div>
        <div className='text-center my-5'>
          <button className='btn btn-lg btn-launch btn-green'>
            Coming Soon
          </button>
        </div>
        <span className='next' onClick={showNext}>
          <Icon icon='akar-icons:chevron-down' />
        </span>
      </div>
    </section>
  )
}
