import rolls_logo from 'assets/Images/Rolls-logo-PNG 1.svg'
import antier from 'assets/Images/antier 1.svg'
import lottiefiles from 'assets/Images/lottiefiles-logo 1.svg'
import blender from 'assets/Images/blender_logo 1.svg'
import unreal from 'assets/Images/unreal.png'
import chad from 'assets/Images/chad.png'
import { Link } from 'react-router-dom'

export const Technologies = () => {
  return (
    <>
      <div className='whitepaper-wrapper' id='litepaper'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6 col-12'>
              <p className='title mb-5'>
                Unleash Your Creativity, Make New Friends, And Be A Part Of Shaping The Future Of This Incredible Metaverse.
                <br />
                <br />
                Call It Your New Home, Live And Play Inside Our Metaverse And Experience The Lifestyle With An Ability To Earn A Living.
                <br />
                <br />
                Join The Growing Community Of Apehattan Today!
              </p>
              <Link
                className='btn btn-lg btn-purple'
                to='apehattan-litepaper.pdf'
                target='_blank'
              >
                Download Litepaper
              </Link>
            </div>

            <div style={{ position: 'relative' }} className='col-lg-6 col-12'>
              <img className='chad' src={chad} alt='Chad' />
            </div>
          </div>
        </div>
      </div>
      <div className='technologies-wrapper'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-4 col-12'>
              <p>Partners</p>
              <img src={rolls_logo} alt='Rolls-logo' />
              <img src={antier} alt='antier' />
            </div>
            <div className='col-lg-4 col-12'>
              <p>Technologies</p>
              <img src={unreal} alt='unreal' />
            </div>
            <div className='col-lg-4'>
              <p style={{ color: 'transparent' }}>Technologies</p>
              <img
                className='logo_bg'
                src={lottiefiles}
                alt='lottiefiles-logo'
              />
              <img className='logo_bg' src={blender} alt='blender_logo' />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
